// Basic Styling

// Fix btn-white showing shadows on focus
.btn-white {
  --bs-btn-bg: var(--bs-white);
}

.font-oswald {
  font-family: 'Oswald', sans-serif;
}

.font-roman {
  font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
}

.offcanvas-header {
  .btn-close {
    filter: invert(100%);
    opacity: 0.75;
    width: 0.8em;
    height: 0.8em;
  }
  .btn-close:hover {
    opacity: 1;
  }
}

.offcanvas-body {
  .dropdown-menu {
    font-size: 1.125rem;
  }
  .dropdown-item:focus, .dropdown-item:hover {
    color: $redunipd;
    background-color: transparent;
  }
}

@include media-breakpoint-down(sm) {
  .offcanvas-end {
    width: 325px !important;
  }
}

@include media-breakpoint-down(md) {
  .offcanvas-header {
    .btn-close {
      font-size: 1.5rem;
    }
  }
  #offcanvas-quicklinks-btn {
    &:focus,
    &:hover {
      color: $redunipd;
    }
  }
  // Apply same font color as dropdown items
  #offcanvas-quicklinks {
    .nav-link {
      color: #212529;
      &:focus,
      &:hover {
        color: $redunipd;  
      }
    }
  }
}

#cab_toplinks .nav-link {
  --bs-nav-link-font-size: 0.875em;
}

@include media-breakpoint-up(md) {
  #cab_toplinks_left .nav-item:first-of-type a {
    padding-left: 0;
  }
  #cab_toplinks_right .nav-item:last-of-type a {
    padding-right: 0;
  }
}

// .logo-primary {
//   flex: 0 0 300px;
// }

#logo-primary, #logo-primary-mobile {
  img {  
    width: 180px;
  }
}

@include media-breakpoint-down(sm) {
  #logo-primary-mobile img {
    width: 160px;
  }
}

.logo-secondary {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

@include media-breakpoint-between(sm,md) {
  .logo-secondary {
    padding-top: 0;
    padding-bottom: 0;
  }
}

#header-search-btn,
#header-lang-switch,
#header-menu-btn,
#homepage-cover-image-caption a,
#homepage-section-banner .banner-item:not(.banner-item-text),
#homepage-biblio-nav-content .nav-item,
#homepage-biblio-tool .banner-item {
  &:hover {
    opacity: 0.85;
  }
}

@include media-breakpoint-down(md) {
  #header-search-btn i.bi-search,
  #header-lang-switch a {
    font-size: 1.5rem !important;
  }
  #header-menu-btn i.bi-justify {
    font-size: 2rem !important;
  }
}

#homepage-biblio-cover-image,
#biblio-collectionexhibition-cover-image {
  overflow: hidden;
  height: 300px;
  img {
    object-fit: cover;
  }
}

#homepage-cover-image {
  overflow: hidden;
  height: 300px;  
  object-fit: cover;
}

.homepage-biblio-cover-info,
.biblio-collectionexhibition-cover-info {
  background-color: rgba(0,0,0, 0.65);
}

.homepage-biblio-cover-info,
.biblio-collectionexhibition-cover-info {
  margin-top: 150px;
}

@include media-breakpoint-down(md) {
  .homepage-biblio-cover-info,
  .biblio-collectionexhibition-cover-info {
    margin-top: 25px;
  }
}

#homepage-cover-image-caption {
  background-color: rgba(22,21,20,0.7);
  max-width: 50%;
}

@include media-breakpoint-down(md) {
  #homepage-cover-image-caption {
    max-width: 75%;
  }
}

#homepage-discovery-search {
  margin-top: 100px;
}

#discovery-search-logo, .discovery-search-logo {
  width: 160px;
}

#discovery-search-logo-mobile, .discovery-search-logo-mobile {
  width: 44px;
}

@include media-breakpoint-up(xxl) {
  #homepage-cover-image {
    height: 450px;
  }
  #homepage-discovery-search {
    margin-top: 180px;
  }
}

@include media-breakpoint-up(md) {
  #homepage-biblio-cover-image,
  #biblio-collectionexhibition-cover-image {
    height: 450px;
  }
}

@include media-breakpoint-up(md) {
  #portal-searchbox .form-control-lg, #homepage-discovery-search .form-control-lg {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1.3rem;
  }
}

.icon-flipped {
  transform: scaleX(-1);
}

#discovery-search-btn,
#libkey-search-btn {
  &:active,
  &:hover,
  &:focus {    
    color: #fff;
    background-color: #3d434c;
    border-color: #3d434c;
  }
  .bi-search::before {
    @extend .icon-flipped;
  }
}

#gd-tab,
#libkey-tab {
  background-color: $verydarkgrayishblueunipd;  
}

#gd-tab,
#libkey-tab {
  &:active,
  &:hover,
  &:focus {
    background-color: #3d434c;
  }
}

#gd-tab .active,
#libkey-tab .active {
  background-color: $redunipd;
}

// Breadcrumb
#breadcrumb-homepage i::before {  
  vertical-align: 0;
}

.card-group > .card {
  margin-bottom: 0 !important;
}

// Bootstrap displays card in card group as row above sm breakpoint, but we want column display above that breakpoint.
@include media-breakpoint-between(sm, md) {
  .card-group {
    flex-flow: column wrap;
  }
}

.more {
  transition: all 0.5s;
}

.more-circled {
  width: 1.7em;
  height: 1.7em;
  border-radius: 50%;
  padding: 3px 6px;
  border: 1px #c8cacc solid;
}

.card-body:hover .more, a:hover .more {
  transform: translateX(0.5rem);
}

@include media-breakpoint-down(md) {
  .border-top-on-medium-and-below {
    border-top: 1px solid #dee2e6 !important;
  }
}

.card-cover {
  overflow: hidden;
  z-index: 1;
  object-fit: cover;
}

#homepage-section-news .homepage-news-card-img {
  @extend .card-cover;
  min-height: 200px;
  max-height: 250px;
}

#homepage-section-librarysystem .homepage-librarysystem-card-img {
  @extend .card-cover;
  height: 250px;
}

#homepage-section-bibdig .homepage-bibdig-card-img {
  @extend .card-cover;
  height: 300px;
}

@include media-breakpoint-down(md) {
  #homepage-section-bibdig .homepage-bibdig-card-img {  
    height: 250px;
  }
}

@include media-breakpoint-down(md) {
  #homepage-section-news article, #homepage-section-featured .card {
    height: auto !important;
  }
}

#homepage-section-news, #homepage-biblio-section-news {
  .news-subtitle {
    color: #5a5a5a;
  }
  .homepage-news-date {
    color: #666f76; // Fix contrast ratio to improve accessibility    
  }
}

// Set equal height for each news content on the right column of the news section
@include media-breakpoint-up(md) {
  #homepage-section-news #tworowsnews {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 1fr;
  }
}

#homepage-section-featured .library-helpline-link,
#homepage-section-banner .banner-item.banner-item-text,
.homepage-biblio-contact-us-link,
.homepage-biblio-suggestions-complaints-link,
#homepage-biblio-restricted-area-banner .banner-item.banner-item-text,
#homepage-biblio-new-arrivals .banner-item.banner-item-text, 
#bibliostaff-section-work-with-us .banner-item.banner-item-text {
  &:hover {
    color: #fff;
    background-color: #3d434c !important;
  }
}

#homepage-section-banner {  
  .banner-item-text {
    height: 80px;    
    overflow: hidden;
  }
}

// Unipd folder view (cover page with links grid)
#unipdfolder-section-cover-image {
  #section-leadimage {
    margin-bottom: 0;
  }
  .unipdfolder-leadimage-card-img {
    @extend .card-cover;    
    height: 400px;
  }
}

// Subpage text content
#section-text,
#formPrologue,
#formEpilogue,
.homepage-biblio-library-hours,
.homepage-biblio-library-hours-note-cnt,
#homepage-biblio-more-hours #library-more-hours-cnt,
#homepage-biblio-how-to-reach #library-how-to-reach-cnt,
#biblio-collectionexhibition-more-info,
#biblio-collectionexhibition-description {
  // Page table of contents creates a float element. Text wraps around it.
  // Setting overflow property seems to solve the issue, see https://css-tricks.com/almanac/properties/o/overflow/#generating-block-formatting-context
  p {
    overflow: hidden;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  li {
    margin-bottom: 0.5rem;
    > ol, > ul {
      margin-top: 0.5rem;
    }
  }
  // Use Bootstrap class to prevent long strings of text from breaking the layout
  @extend .text-break;
  // Use Boostrap styles for table
  table {
    @extend .table;
    @extend .table-bordered;
  }
  // Use Bootstrap styles for blockquote
  blockquote {
    @extend .blockquote;
  }
  figure {
    img {
      @extend .figure-img;
    }
    figcaption {
      @extend .figure-caption;
    }
  }
  // TinyMCE editor adds image-responsive class when "Responsive" is selected in "Insert Image" -> "Align"
  // but it seems the class isn't defined anywhere, so let's declare it here extending Bootstrap class.
  .image-responsive {
    @extend .img-fluid;
  }
  .accordion-tmpl {
    .accordion-item {
      .accordion-header {
        @extend .col-md-8;
        @extend .h4;
      }
    }
  }
  .card-group-tmpl {
    .card {
      border: 1px solid #fff!important;
    }
    .card-header {
      @extend .h5;
      @extend .bg-greyunipd;
    }
    .card-footer {
      @extend .bg-greyunipd;
    }
    .card-body {
      padding: 1.5rem!important;
    }
    .card-title {
      margin-bottom: 1rem!important;
      @extend .h4;
      a {
        @extend .text-redunipd;
        text-decoration-thickness: 1px;  
      }
    }    
    .card-title a:hover {
      text-decoration: underline;
    }
    // img {
    //   @extend .card-img-top;
    //   @extend .card-cover;
    //   max-height: 250px;
    // }
    ul {
      padding-left: 1rem;
    }
  }
  .contactinfo-box-tmpl {
    @extend .col-md-8;
    background-color: $lightgreyunipd;
    padding: 1.5rem 1.5rem .5rem 1.5rem !important;
    .contactinfo-box-title {
      @extend .h4;
    }
  }
  .download-link-tmpl {
    @extend .col-md-6;
    display: inline-block;
    text-decoration: none !important;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem .25rem .25rem 0 !important;   
    .download-link-icon {
      clear: both;
      float: right;
    }
  }
  // import barceloneta rules for responsive images and picture variants
  @import "@plone/plonetheme-barceloneta-base/scss/content_base";
}

@include media-breakpoint-down(md) {
  #section-text,
  #formPrologue,
  #formEpilogue {
    .download-link-tmpl {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(md) {
  #unipdfolder-section-cover-image .unipdfolder-leadimage-card-img {
  // #section-text .card-group-tmpl img {
    height: 250px;
  }
}

// Accordion icon positioning
.accordion-button {
  &::after {
    display: none;
  }
  &::before {
    flex-shrink: 0;
    width: $accordion-icon-width;
    height: $accordion-icon-width;
    content: "";
    margin-right: 0.5rem;
    background-image: escape-svg($accordion-button-icon);
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
    @include transition($accordion-icon-transition);
  }
  &:not(.collapsed) {
    &::after {
      display: none;
    }
    &::before {
      background-image: escape-svg($accordion-button-active-icon);
      transform: $accordion-icon-transform;
    }
  }
}

// Subpage table of contents
#section-text .pat-autotoc:not(.autotabs) {
  .autotoc-nav {
    @extend .col-md-4;
    float: right;
    min-width: 150px;
    background-color: $lightgreyunipd;
    border: 0;
    border-top: 0.5rem solid $greyunipd;
    margin: 0 0 $spacer * 3 $spacer;    
    padding: $spacer;
    a {
      display: block;
      text-decoration: none;
      margin-bottom: $spacer * 0.25;
    }
    .autotoc-level-1 {
      margin-left: 0;
      font-size: $font-size-base * 1.15;
    }
    .autotoc-level-2 {
      margin-top: $spacer * 0.75;
      margin-left: $spacer;
      font-size: $font-size-base;
    }
    .autotoc-level-3 {
      margin-left: $spacer * 2;
      font-size: $font-size-base * 0.85;
    }
  }
}

// Hide table of contents on small screen sizes
@include media-breakpoint-down(md) {
  #section-text .pat-autotoc .autotoc-nav {
    display: none;
  }
}

// Footer
.footer-text {
  font-size: 80% !important;
  color: var(--bs-white);
}

.footer-section-title {  
  font-size: 0.85rem !important;
  letter-spacing: 0.05rem;
}

#footer-section-links li:not(:last-child) {
  margin-bottom: 0.25rem;
}

#section-category {
  .keywords-viewlet > a {
    margin-bottom: 0.5rem;
  }
}

// -----
// Forms - copied from @plone/plonetheme-barceloneta-base/scss/forms.scss
.pat-autotoc.autotabs {
  .autotoc-nav {
    margin-bottom: $spacer;
  }
}

label .required:after,
label.required:after {
  color: $danger;
  content: '•';
  font-size: $lead-font-size;
  line-height: 1;
}

/* invalid-feedback */
.field.error .invalid-feedback {
  display: block;
}

.form-group {
  .select2-container {
    display: block;
  }
}

.formControls {
  margin-top: $spacer * 1.5;
  margin-bottom: $spacer * 0.5;
}

/* relateditems widget */
.pattern-relateditems-result {
  .pattern-relateditems-result-browse-wrapper {
    .pattern-relateditems-result-browse {
      font-size: $font-size-base;
    }
  }
}

.select2-results .select2-highlighted {
  color: $link-hover-color;
}

.pattern-relateditems-item-title,
.pattern-relateditems-result-title {
  &, &[class*="contenttype-"]:before {
    font-size: $lead-font-size;
  }
}
// -----

// Navigation bar
.has_subtree > a:not(.nav-link) {
  display: none;
}

// TinyMCE text editor
.mce-content-body {
  a {
    text-decoration: underline;
  }
  li {
    margin-bottom: 0.5rem;
    > ol, > ul {
      margin-top: 0.5rem;
    }
  }
  .accordion-tmpl {
    .accordion-item {
      .accordion-header {
        @extend .col-md-8;
        @extend .h4;
      }
      // Show accordion body border to ease editing
      .accordion-body {
        border: 1px solid #000;
      }
    }
  }
  .card-group-tmpl {
    .card {
      border: 1px solid #fff!important;
    }
    .card-body {
      padding: 1.5rem!important;
    }
    .card-header {
      @extend .h5;
      @extend .bg-greyunipd;
    }
    .card-footer {
      @extend .bg-greyunipd;
    }
    .card-title {
      margin-bottom: 1rem!important;
      @extend .h4;
      a {
        @extend .text-redunipd;
      }
    }    
    .card-title a:hover {
      text-decoration: underline;
    }
    img {
      @extend .card-img-top;
      @extend .card-cover;
      max-height: 250px;
    }
    ul {
      padding-left: 1rem;
    }
  }
  .contactinfo-box-tmpl {
    @extend .col-md-8;
    background-color: $lightgreyunipd;
    padding: 1.5rem 1.5rem .5rem 1.5rem !important;
    .contactinfo-box-title {
      @extend .h4;
    }
  }
  .download-link-tmpl {
    @extend .col-md-6;
    display: inline-block;
    text-decoration: none !important;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem .25rem .25rem 0 !important;
    .download-link-icon {
      clear: both;
      float: right;
    }
  }
  #collapse-acc {
    display: block;
  }  
  // Use Boostrap styles for table
  table {
    @extend .table;
    @extend .table-bordered;    
  }
  // Use Bootstrap styles for blockquote
  blockquote {
    @extend .blockquote;
  }
}

// TinyMCE custom formats
p.redalert {
  @extend .col-md-6;
  background: $lightgreyunipd;
  padding: 1rem;
  border-left: 1rem solid $redunipd;  
}
p.featured {
  @extend .col-md-6;
  background: $lightgreyunipd;
  padding: 1rem;
  border-left: 1rem solid $greyunipd;  
}

// from Plone, define documentFirstHeading so compatibility with extensions is improved
.documentFirstHeading {
  @extend .text-redunipd;
  @extend .font-roman;
  @extend .display-6;
}

// Cookie banner
@include media-breakpoint-down(sm) {  
  #explicitsubmit {
    margin-top: .5rem;
  }
}

// Fix font color of numbers in the footer on Safari for iOS
footer#portal-footer a[href^=tel] {
  color: inherit;
  text-decoration: none;
}

// Wrap text around Bootstrap icon
.icon-beside-text {
  &.bi::before,
  &[class*=" bi-"]::before,
  &[class^="bi-"]::before {
    display: inline;
  }
}

#library-card {
  #section-text {
    .form-label {
      font-size: 1.5rem !important;
      letter-spacing: 1.5px;
      font-weight: 400;
    }
    .form-label,
    .select-widget.choice-field {
      font-family: 'Oswald', sans-serif;
    }
  }
  #library-card-map {
    min-width: 50%;
    .leaflet-popup-content h3 {
      font-size: 1.25rem!important;
    }
  }
}

@include media-breakpoint-down(md) {
  #library-card #library-card-map
  .pat-leaflet {
    min-height: 300px;
    .leaflet-popup-content {
      width: 200px!important;
    }
  }
}

// Library hours table in biblio view
#library-hours-single-table {
  table > :not(:first-child) {
    border-top: none;
  }
  table > :not(caption) > * > * {
    padding: 1rem .75rem;    
  }
  thead {
    background-color: $lightgreyunipd;
  }
}

// Library map and list
#library-map-list-intro p {
  font-size: 1.15rem !important;
}

#library-map .pat-leaflet {
  min-height: 300px;
}

@include media-breakpoint-down(md) {
  #library-map .pat-leaflet .leaflet-popup-content {
    width: 200px!important;    
  }
}

#library-list {
  min-height: 100px;
  .library-thumbnail {
    @extend .card-cover;
    height: 200px;
  }
  .card .card-body a:not(.btn) {
    text-decoration: underline;    
  }
}

@include media-breakpoint-up(md) {
  #library-map .pat-leaflet {
    min-height: 350px;
  }
  #library-list {
    min-height: 300px;
  }
}

/* Library hours table */
.highlighted {
  background-color: #fdfd96;
}

#library-hours-intro p {
  font-size: 1.15rem !important;
}

#library-hours-table {
  table {
    position: relative;
  }
  min-height: 300px;
  table > :not(:first-child) {
    border-top: none;
  }
  table > :not(caption) > * > * {
    padding: 1rem .75rem;    
  }
  thead th.today, tbody td.today {
    @extend .highlighted;
  }
  thead {
    th:first-child {
      width: 12%;
    }
    th {
      background-color: $lightgreyunipd;
      position: sticky;
      top: 0;
      width: 8.8%;
    }
  }
  tbody {
    th {
      width: 12%;
    }
    td {
      width: 8.8%;
      &:not(.no-table-data-item) {
        text-align: center;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #library-hours-table {
    overflow-x: scroll;
  }
}

// Darker colours to meet WCAG AA accessibility specifications
#library-hours-table, #library-list {
  .text-success {
    color: #177d4c!important;
  }
  .text-danger {    
    color: #9c1c28!important;
  }
}

@include media-breakpoint-up(md) {
  #homepage-biblio-nav-content {
    box-shadow: 0px 25px 20px -25px rgba(0,0,0,0.15);
  }
}

.homepage-biblio-anchor {
  scroll-margin-top: 150px;
  // scroll-padding-top: 150px;
}

@include media-breakpoint-down(lg) {
  .homepage-biblio-anchor {
    scroll-margin-top: 420px;
  }
}

.homepage-biblio-anchor:focus {
  outline: none;
}

.homepage-biblio-sede {
  margin-top: 1rem;
}

#homepage-biblio-section-contacts-hours > .homepage-biblio-sede ~ .homepage-biblio-sede {
  margin-top: 1.5rem;
}

.homepage-biblio-library-locator-link {
  margin-left: 2.25rem;
}

.homepage-biblio-services-card-img,
.homepage-biblio-resources-card-img,
.homepage-biblio-collections-card-img {
  height: 150px;
  object-fit: cover;
}

/* Affluences timetable widget */
.homepage-biblio-library-hours {
  .affluences-timetable>.affluences-timetable-container>.day,
  .affluences-timetable>.affluences-timetable-container>.day.closed
  .affluences-timetable>.affluences-timetable-container>.affluences-timetable-navbar {
    color: var(--bs-body-color) !important;
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 1.15rem !important;
  }

  .affluences-timetable>.affluences-timetable-container>.affluences-timetable-navbar>.range {
    font-family: var(--bs-font-sans-serif) !important;
  }

  .affluences-timetable .timetable-title-long,
  .affluences-timetable>.affluences-timetable-container>.day .timetable-title {  
    font-weight: 500 !important;
    font-size: 1.15rem !important;
  }

  .affluences-timetable>.affluences-timetable-container>.day.current .timetable-time-container,
  .affluences-timetable>.affluences-timetable-container>.day.current .timetable-title,
  .affluences-timetable>.affluences-timetable-container>.day.current .timetable-title-long {  
    color: $redunipd !important;
    font-weight: 500 !important;
  }

  .affluences-timetable>.affluences-timetable-container>.affluences-timetable-navbar>.next-week,
  .affluences-timetable>.affluences-timetable-container>.affluences-timetable-navbar>.prev-week {  
    padding: 5px 10px !important;
    border-radius: 0 !important;
    border: 1px solid #dee2e6 !important;
  }
}

.homepage-biblio-library-hours-custom table {
  td, th, tr {
    border: 0 !important;
    background-color: $light4 !important;
  }
}

/* Affluences occupation rate widget */
.homepage-biblio-library-affluences {
  .affluences-counter .owner {
    display: none;
  }
  .affluences-counter .title .will_close {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .homepage-biblio-occupation-rate-widget {
    width: 50%;
  }
}

.homepage-biblio-section-cover-image {
  height: 150px;
}

#btn-back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: none;
  z-index: 1001;
}

@include media-breakpoint-down(md) {
  #btn-back-to-top {
    display: none !important;
  }
}

#library-name-navbar-internal {
  display: none;
}

// Always show library name when showing collapsed navigation menu
// The media breakpoint matches the class navbar-expand-xxl used in the navbar element. They must be the same.
@include media-breakpoint-down(xxl) {
  #library-name-navbar-internal {
    display: block !important;
  }
}

.bibliostaff-wall-card-img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.event-summary .timezone {
  display: none;
}

.event-when abbr, .event-occurences abbr {
  text-decoration: none;
  cursor: pointer;
}

// Library home page mobile hamburger icon animation
// https://stackoverflow.com/a/71963380
.homepage-biblio-menu-icon-mobile .icon-bar {
  display: block;
  background-color: $white;
  height: 3px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}

.homepage-biblio-menu-icon-mobile span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
}

.homepage-biblio-menu-icon-mobile span:nth-child(2) {
  opacity: 1;
}

.homepage-biblio-menu-icon-mobile span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
}

.homepage-biblio-menu-icon-mobile span:nth-child(1) {
  margin-top: 0.3em;
}

.navbar-toggler:not(.collapsed) .homepage-biblio-menu-icon-mobile span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) .homepage-biblio-menu-icon-mobile span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) .homepage-biblio-menu-icon-mobile span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
}

#homepage-biblio-library-more-services,
#homepage-biblio-library-more-resources,
#homepage-biblio-library-more-collections,
#homepage-biblio-more-contacts,
#homepage-biblio-more-hours,
#homepage-biblio-how-to-reach {
  button[aria-expanded=true] .bi-chevron-down {
    display: none;
  }
}

#homepage-biblio-library-more-services,
#homepage-biblio-library-more-resources,
#homepage-biblio-library-more-collections,
#homepage-biblio-more-contacts,
#homepage-biblio-more-hours,
#homepage-biblio-how-to-reach {
  button[aria-expanded=false] .bi-chevron-up {
    display: none;
  }
}