// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

// Required
@import "bootstrap/scss/functions";

// Custom colors
$lightgreyunipd: #f2f2f2;
$mediumgreyunipd: #e8e8e8;
$greyunipd: #ddd;
$darkgrayishblueunipd: #7b7f88;
$verydarkgrayishblueunipd: #484f59;
$lightblackunipd: #454953;
$rhinoblackunipd: #3e454f;
$redunipd: #9b0014;
$darkredunipd: #7c0010;
$light1: #f0eff4;
$light2: #ededed;
$light3: #f5f1e5;
$light4: #f8f8f6;

$custom-theme-colors: (
  "lightgreyunipd": $lightgreyunipd,
  "mediumgreyunipd": $mediumgreyunipd,
  "greyunipd": $greyunipd,
  "darkgrayishblueunipd": $darkgrayishblueunipd,
  "verydarkgrayishblueunipd": $verydarkgrayishblueunipd,
  "lightblackunipd": $lightblackunipd,  
  "rhinoblackunipd": $rhinoblackunipd,
  "redunipd": $redunipd,
  "darkredunipd": $darkredunipd,
  "light1": $light1,
  "light2": $light2,
  "light3": $light3,
  "light4": $light4,
);

// Toggle global options
$enable-rounded: false;
$enable-shadows: false;
$enable-dark-mode: false;

// Set primary color here so that all *-primary (e.g., btn-primary, bg-primary, text-primary, etc.) classes already existing
// in default Plone templates get at least a style coherent with the theme palette color. 
$primary: $verydarkgrayishblueunipd;
$info: $greyunipd;

// Accordion
$accordion-button-padding-x: 0;
$accordion-color: $lightblackunipd;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $accordion-color;
$accordion-button-focus-box-shadow: none;
$accordion-icon-color: $accordion-color;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z'/></svg>");

// Border
// Fix: decrease border radius for smaller rounded corners when used as e.g. rounded-end class
$border-radius: .2rem;

// Breadcrumb
$breadcrumb-bg: $lightgreyunipd;
$breadcrumb-active-color: $redunipd;
$breadcrumb-divider: quote(">");

// Button
$btn-focus-box-shadow: none;

// Card
$card-bg: $lightgreyunipd;
$card-border-width: 0;

// Dropdown
$dropdown-bg: $lightgreyunipd;
$dropdown-border-width: 0;
$dropdown-link-active-bg: $redunipd;

// Form
$input-focus-box-shadow: none;
$input-group-addon-bg: $lightgreyunipd;

// Link
$link-color: $lightblackunipd;
$link-hover-color: $redunipd;
$link-decoration: none;

// Navbar
$navbar-light-color: $lightblackunipd;
$navbar-light-hover-color: $redunipd;
$navbar-light-active-color: $redunipd;
$nav-link-focus-box-shadow: none;

// Offcanvas
$offcanvas-bg-color: $lightgreyunipd;

// Required
@import "bootstrap/scss/variables";

// Remove $enable-dark-mode: false if you want to use dark theme variables;
// @import "bootstrap/scss/variables-dark";

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

// Required
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/helpers";

// Make border utilities responsive, cf. https://getbootstrap.com/docs/5.3/utilities/api/#enable-responsive
$utilities: map-merge(
  $utilities, (
    "border-start": map-merge(
      map-get($utilities, "border-start"),
      ( responsive: true ),
    ),
    "border-end": map-merge(
      map-get($utilities, "border-end"),
      ( responsive: true ),
    )
  )
);

// Utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

@mixin gradient-x-bicolor($start-color: $gray-700, $end-color: $gray-800, $direction: right) {
  background: linear-gradient(to $direction, $start-color 0%, $start-color 50%, $end-color 50%, $end-color 100%);
}

.bg-redunipd-gradient {
  @include gradient-x-bicolor($white, $redunipd)
}

.bg-redunipd-gradient-left {
  @include gradient-x-bicolor($white, $redunipd, left)
}

.bg-lightgrey-gradient {
  @include gradient-x-bicolor($white, $lightgreyunipd)
}

.bg-lightgrey-gradient-left {
  @include gradient-x-bicolor($white, $lightgreyunipd, left)
}

.bg-mediumgrey-gradient {
  @include gradient-x-bicolor($white, $mediumgreyunipd)
}

.bg-mediumgrey-gradient-left {
  @include gradient-x-bicolor($white, $mediumgreyunipd, left)
}

.bg-greyunipd-gradient {
  @include gradient-x-bicolor($white, $greyunipd)
}

.bg-greyunipd-gradient-left {
  @include gradient-x-bicolor($white, $greyunipd, left)
}

.bg-light2-gradient {
  @include gradient-x-bicolor($white, $light2)
}

.bg-light2-gradient-left {
  @include gradient-x-bicolor($white, $light2, left)
}

.bg-light4-gradient {
  @include gradient-x-bicolor($white, $light4)
}

.bg-light4-gradient-left {
  @include gradient-x-bicolor($white, $light4, left)
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .border-darkredunipd-#{$breakpoint} {border-color: $darkredunipd !important;}
    .bg-light2-#{$breakpoint} {background-color: $light2 !important;}
    .bg-light4-#{$breakpoint} {background-color: $light4 !important;}
    .bg-white-#{$breakpoint} {background-color: $white !important;}
    .bg-redunipd-#{$breakpoint}-gradient {background: linear-gradient(to right, $white 0%, $white 50%, $redunipd 50%, $redunipd 100%);}
    .bg-redunipd-#{$breakpoint}-gradient-left {background: linear-gradient(to left, $white 0%, $white 50%, $redunipd 50%, $redunipd 100%);}
    .bg-lightgreyunipd-#{$breakpoint}-gradient {background: linear-gradient(to right, $white 0%, $white 50%, $lightgreyunipd 50%, $lightgreyunipd 100%);}
    .bg-lightgreyunipd-#{$breakpoint}-gradient-left {background: linear-gradient(to left, $white 0%, $white 50%, $lightgreyunipd 50%, $lightgreyunipd 100%);}
    .bg-mediumgreyunipd-#{$breakpoint}-gradient {background: linear-gradient(to right, $white 0%, $white 50%, $mediumgreyunipd 50%, $mediumgreyunipd 100%);}
    .bg-mediumgreyunipd-#{$breakpoint}-gradient-left {background: linear-gradient(to left, $white 0%, $white 50%, $mediumgreyunipd 50%, $mediumgreyunipd 100%);}
    .bg-greyunipd-#{$breakpoint}-gradient {background: linear-gradient(to right, $white 0%, $white 50%, $greyunipd 50%, $greyunipd 100%);}
    .bg-greyunipd-#{$breakpoint}-gradient-left {background: linear-gradient(to left, $white 0%, $white 50%, $greyunipd 50%, $greyunipd 100%);}
    .bg-light2-#{$breakpoint}-gradient {background: linear-gradient(to right, $white 0%, $white 50%, $light2 50%, $light2 100%);}
    .bg-light2-#{$breakpoint}-gradient-left {background: linear-gradient(to left, $white 0%, $white 50%, $light2 50%, $light2 100%);}
    .bg-light4-#{$breakpoint}-gradient {background: linear-gradient(to right, $white 0%, $white 50%, $light4 50%, $light4 100%);}
    .bg-light4-#{$breakpoint}-gradient-left {background: linear-gradient(to left, $white 0%, $white 50%, $light4 50%, $light4 100%);}
  }
}

// Theme fonts
@import "oswald";

// Bootstrap Icons
// local import:
// @import "bootstrap-icons";
// Use CDN font and local icons CSS
$bootstrap-icons-font-src: url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/fonts/bootstrap-icons.woff2") format("woff2"),
                           url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/fonts/bootstrap-icons.woff") format("woff");
@import 'bootstrap-icons/font/bootstrap-icons';
// Use full CDN: does not work because it include everything inside the CSS, so the relative path to fonts point to our instance and not remote.
// @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");
// Alternative solution: add it as resource in the Plone resource registry, but consider adding a condition to separate authenticated and non-authenticated UI

// plone variables (used in toolbar)
// Plone specific colors
// colors
//$state-draft-color:                 #fab82a!default; // lime-yellow //draft is visible
//$state-pending-color:               #e2e721!default; // orange
//$state-private-color:               #c4183c!default; // red
//$state-internal-color:              #fab82a!default; // is draft
//$state-internally-published-color:  #883dfa!default; // is intranet
//$plone-link-color:                  #007bb1!default; //plone blue made slightly darker for wcag 2.0
//
//// Toolbar
//$plone-toolbar-bg: rgba(0, 0, 0, 0.9);
//$plone-toolbar-submenu-bg: rgba(20, 20, 20, 0.95);
//$plone-toolbar-font-primary: "Roboto Condensed", sans-serif;
//$plone-toolbar-font-secondary: "Roboto", sans-serif;
//$plone-toolbar-separator-color: rgba(255, 255, 255, 0.17);
//$plone-toolbar-link: $plone-link-color;
//$plone-toolbar-link-hover-color: $plone-link-color;
//$plone-toolbar-text-color: rgba(255, 255, 255, 0.9);
//$plone-toolbar-submenu-text-color: #fff;
//$plone-toolbar-submenu-header-color: lighten(#000, 80%);
//
//$plone-toolbar-locked-color: var(--bs-warning); // is intranet
//
//$plone-toolbar-width: 220px;
//$plone-toolbar-width-collapsed: calc($spacer * 2.25);
//$plone-toolbar-top-height: calc($spacer * 2.5);

// Basic styles and colormode from Plone theme Barceloneta - imported with --load-path=node_modules in package.json
// Plone colors
@import "@plone/plonetheme-barceloneta-base/scss/variables.colors.plone";
@import "@plone/plonetheme-barceloneta-base/scss/variables.colors.dark.plone";
@import "@plone/plonetheme-barceloneta-base/scss/root_variables";
@import "@plone/plonetheme-barceloneta-base/scss/content";
@import "@plone/plonetheme-barceloneta-base/scss/toolbar";
@import "@plone/plonetheme-barceloneta-base/scss/scaffolding";

// Theme-specific styles
@import "base";

// Theme-specific print styles
@import "print";